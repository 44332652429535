import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ExtraErrorData } from '@sentry/integrations';
import { ApolloProvider } from '@apollo/client';
import { Toaster, toast } from 'react-hot-toast';
import { i18n } from '@spacefill/shared/src/i18n/I18n';
import { client as ApolloClient, pingGraphQLServer } from '@spacefill/shared/src/utils/Apollo';
import { UserProvider } from '@spacefill/shared/src/utils/UserContext';
import { TopBannerContextProvider } from '@spacefill/shared/src/utils/TopBannerContext';
import { ThemeStateProvider } from '@spacefill/shared/src/utils/ThemeStateContext';
import { overrideConsoleErrorToAddSentryCapture } from '@spacefill/shared/src/utils/OverrideConsole';
import { FeatureTogglingProvider, FeatureTogglingToolbar } from '@spacefill/shared/src/utils/FeatureToggling';
import '@spacefill/shared/src/utils/YupMethods';
import { datadogRum } from '@datadog/browser-rum';

import { RouterContext } from './router/RouterContext';
import { featureSettings } from './Features';

import '@spacefill/uikit/src/index.css';

const sentryDSN = window?._spacefill_env_?.sentryDsn || import.meta.env?.VITE_ADMIN_CONSOLE_SENTRY_DSN;
const sentryEnvironment =
    window?._spacefill_env_?.sentryEnvironment || import.meta.env?.VITE_ADMIN_CONSOLE_SENTRY_ENVIRONMENT;
const datadogApplicationId =
    window?._spacefill_env_?.datadogApplicationId || import.meta.env?.VITE_ADMIN_CONSOLE_DATADOG_APPLICATION_ID;
const datadogClientToken =
    window?._spacefill_env_?.datadogClientToken || import.meta.env?.VITE_ADMIN_CONSOLE_DATADOG_CLIENT_TOKEN;

const enableMockTesting = new URLSearchParams(window.location.search).get('enableMockTesting');
if (enableMockTesting) {
    localStorage.setItem('enableMockTesting', '1');
}

if (datadogApplicationId) {
    datadogRum.init({
        applicationId: datadogApplicationId,
        clientToken: datadogClientToken,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'datadoghq.eu',
        service: 'admin-console',
        env: import.meta.env?.VITE_ADMIN_CONSOLE_SENTRY_ENVIRONMENT,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        allowedTracingUrls: [(url) => url.includes('spacefill.fr')],
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
    });
}

if (typeof sentryDSN === 'string' && sentryDSN !== '') {
    console.info('Sentry enable');
    Sentry.init({
        dsn: sentryDSN,
        release: import.meta.env?.VITE_ADMIN_CONSOLE_SENTRY_RELEASE,
        ignoreErrors: ['ResizeObserver loop limit exceeded'],
        integrations: [
            new ExtraErrorData({
                depth: 11,
            }),
            new Integrations.BrowserTracing(),
        ],
        tracesSampleRate: 0.5,
        normalizeDepth: 11,
        environment: sentryEnvironment || 'unknown',
    });
    overrideConsoleErrorToAddSentryCapture();
}

if (window.navigator.userAgent === 'puppeteer') {
    window.toast = toast;
}

const App = () => {
    return (
        <ApolloProvider client={ApolloClient}>
            <FeatureTogglingProvider featureSettings={featureSettings}>
                <ThemeStateProvider>
                    <TopBannerContextProvider>
                        <UserProvider>
                            <FeatureTogglingToolbar />
                            <RouterContext />
                            <Toaster />
                        </UserProvider>
                    </TopBannerContextProvider>
                </ThemeStateProvider>
            </FeatureTogglingProvider>
        </ApolloProvider>
    );
};

pingGraphQLServer((error) => {
    toast.error(i18n.t('Internal error!'));
    console.error(error);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
